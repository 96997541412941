<template>
  <div v-if="detail &&detail.listOrderItem" class="page" style="background-color:white;"> 
    <!-- 导航 -->
    <!-- <van-nav-bar
      title="订单详情"
      left-arrow
      @click-left="$router.back()"
      :fixed="true"
      placeholder
    ></van-nav-bar> -->

<Head :type="0">
  <template #title>
    订单详情
  </template>
</Head>
<div class="main">
    <!-- 导航 -->
    
    <div style="margin-top: 10px; background-color: #f6f6f6 ; ">
      <!-- 收货地址 -->
      <div style="display: flex;  padding: 15px 16px; align-items: center; background-color:white; border-top:1px solid #f6f6f6; border-radius:5px
 ">
        <div style="font-size: 20px">
          <van-icon name="location-o" />
        </div>
        <div style="margin-left: 15px">
          <!-- <div style="display: flex; align-items: center">
            <van-tag type="danger" size="mini" style="margin-right: 6px"
              >默认</van-tag
            >
            <van-tag type="primary" size="mini" style="margin-right: 6px"
              >家</van-tag
            >
            <span class="color333 fz12 ">{{ locate.address }}</span>
          </div> -->
          <div class="color333" style="font-weight: 600; margin: 4px 0">
            {{ locate.address }}
          </div>
          <div class="color333 fz12">
            <span>{{ locate.userName }}</span>
            &nbsp;
            <span>{{ locate.telNum }}</span>
          </div>
        </div>
      </div>
      <!-- 收货地址 -->
      <div style="background-color: #f6f6f6; height: 10px"></div>
      <!-- 商品价格和详情 -->
      <div style="padding: 15px 16px; background-color:white;  border-radius:5px">
        <div class="color333" style="font-weight: 600">
          {{ shopName }}
        </div>
        <div style="display: flex; justify-content: space-between; padding: 15px 0 ; border-radius:5px" v-for="item in detail.listOrderItem"  :key="item.id" >
         
      
          <div>
            <van-image  width="80px"  height="80px" fit="cover"  :src="item.picUrl" />
           
          </div>
          <div style="flex: 1; margin-left: 10px">
            <div style="height:60% ; display: flex; ">
              <div style="flex: 1 ; justify-content: space-between;display:flex; flex-direction: column;">
                <div  class="van-multi-ellipsis--l2 name"  style="font-weight: 500;box-sizing:border-box;"  >{{  item.spuName}} </div>
                 
                </div>  
             
              <div style="width: 90px; color: #a80000; text-align: right">
                <span>￥</span>
                <span class="fz18" style="font-weight: 600">
                  {{item.salesPrice  }}
                </span>
              </div>
            </div>

            <div style="height: 30px; display: flex; align-items: flex-end">
              <div class="fz12 color999" style="flex: 1">
                <span>规格:{{ item.specInfo ||'无'}}</span>
              </div>
              <div class="fz12 color999" style="width: 90px; text-align: right">
                <span>×</span>
                <span>{{ item.quantity }}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div v-for="(item, index) in list" :key="index" class="list">
            <span class="color333">{{ item.title }}</span>
            <span class="color999">{{ item.value }}</span>
          </div>
          <div class="list" style="margin-top: 10px">
            <span class="color333">
              {{ ( detail.isPay - 0  )? "实付款" : '待付款'}}
              <!-- <span v-if="isPay == 0">需付款</span>
              <span v-else>已付款</span> -->
            </span>
            <span style="color: #a80000">
              <span>￥</span>
              <span>{{ detail.paymentPrice  }}</span>
            </span>
          </div>
        </div>
      </div>
      <!-- 商品价格和详情 -->
      <div style="background-color: #f6f6f6; height: 10px"></div>
      <!-- 订单信息 -->
      <div style="padding: 15px;background-color:white; margin:2px; border-radius:5px">
        <div class="color333" style="font-weight: 600; margin-bottom: 10px">
          订单信息
        </div>

        <div class="list" >
          <span class="color333">
            <span>订单备注：</span>
            <span>{{ detail.userMessage }}</span>
          </span>
        </div>

        <div class="list">
          <span class="color333" style="display:flex;width：100%">
            <span style="white-space:nowarp;">订单编号：</span>
            <span  class="van-ellipsis" style="flex:1;">{{ detail.orderNo }}</span>
          </span>
          <copy-text :text="detail.orderNo">
            <span style="color: #ff5200;width:2rem;">复制</span>
          </copy-text>
        </div>

        <div class="list" v-if="detail.transactionId">
          <span class="color333"  style="width:100%">
            <span style="white-space:nowarp;">订单交易号：</span>
            <span class="van-ellipsis" style="flex:1;width:5rem">{{ detail.transactionId }}</span>
            <copy-text :text="detail.transactionId">
            <span  style="color: #ff5200;white-space:nowrap; width:100%;text-align:right;">复制</span>
          </copy-text>
          </span>
          
        </div>

        <div v-for="(item, index) in list2" :key="index">
          <div class="list" v-if="item.value">
            <span class="color333">
              <span>{{ item.title }}</span>
              <span>{{ item.value }}</span>
            </span>
          </div>
        </div>
      </div>
      <!-- 订单信息 -->
      <div style="background-color: #f6f6f6; height: 66px"></div>
    </div>
 </div>
    <!-- 底部 -->
    <div class="bottom"  >
      <!-- <van-button
          class="bottom-btn color999"
          size="small"
          style="border-color: #999"
          @click="reloadPage"
          >123</van-button
        > -->
      <!-- 待付款 -->
      <div v-if="!status && isPay == 0">
        <van-button
          class="bottom-btn color999"
          size="small"
          style="border-color: #999"
          @click="cancelOrder"
          >取消订单</van-button
        >
        <van-button
          v-if="Date.now()-(new Date(detail.createTime.replace(/-/ig,'/')).getTime())-15*60*1000<0"
          class="bottom-btn linear-btn-color"
          size="small"
          type="primary"
          style="border: none"
           color="#FF3C00"
          @click="payShow = true"
          >付款</van-button
        >
      </div>
    
          <!-- <van-button v-if="detail.orderRefundStatu != null"
          class="bottom-btn color999"
          size="small"
          style="border-color: #999"
            @click="goRefundDetail()"
          >售后/退款</van-button> -->
        
      <div v-if="(detail.status == 3||detail.status == 1 || detail.status==2|| detail.orderType!=0)&& parseInt(detail.listOrderItem[0].status)<1 ">
        <!-- && appraisesStatus == 1 -->
        <van-button 
        v-if="detail.isPay==='1'&&detail.listOrderItem[0].status==0&&detail.orderRefundStatu==null"
       @click="$router.push({path:'refund',query:{orderType:'0', orderId:detail.orderNo,  status:'1',id:detail.listOrderItem[0].id}})"
          class="bottom-btn color999"
          size="small"
          style="border-color: #999"
          >申请退款</van-button
        >
       
        <van-button v-if="detail.orderType==0&&detail.type==4&&detail.spuRefundStatus==0&&detail.status!=5"
          class="bottom-btn linear-btn-color"
          size="small"
          type="primary"
          style="border: none"
            @click="goGoodsDetail()"
          >再次购买</van-button
        >
      </div>
        <!-- <van-button v-if="detail.orderType==0&&detail.type==4&&detail.spuRefundStatus==0&&detail.status!=5"
          class="bottom-btn linear-btn-color"
          size="small"
          type="primary"
          style="border: none"
            @click="goGoodsDetail()"
          >cha</van-button
        > -->
      
      <!-- 代发货 -->
      <!-- <div v-if="status == 1">
        <van-button
          class="bottom-btn color999"
          size="small"
          color="#FF3C00"
          style="border-color: #999"
          @click="$toast('提醒成功')"
          >提醒发货</van-button
        >
      </div> -->

      <!-- 待收货 -->
     
        <!-- <van-button
          class="bottom-btn color999"
          size="small"
          style="border-color: #999"
          >延长收货</van-button
        > -->
        <van-button
        v-if="detail.status=='2'||detail.status=='4'"
        @click="$router.push({path:'logistics',query:{id:detail.logisticsId,type:0,address:locate.address,orderNo:detail.orderNo,img:detail.listOrderItem[0].picUrl}})"
          class="bottom-btn color999"
          size="small"
          style="border-color: #999"
          >查看物流</van-button
        >
        <van-button
        v-if="detail.status==='2'&&detail.listOrderItem[0].status==='0'"
          class="bottom-btn linear-btn-color"
          size="small"
          type="primary"
          style="border: none"
           color="#FF3C00"
          @click="Receiving()"
          >确认收货</van-button
        >
        <!-- <div v-if="detail.status=='4'||detail.status==5 ||detail.orderRefundStatu==11 ">
         <van-button 
          class="bottom-btn linear-btn-color"
          size="small"
          type="primary"
          style="border: none"
           @click="goGoodsDetail()"
          >再次购买</van-button>

      </div> -->
    
        <!-- <van-button 
        v-if="parseInt(detail.listOrderItem[0].status) >0 "
        class="bottom-btn linear-btn-color"
          size="small"
          type="primary"
          style="border: none"
           color="#FF3C00"
           @click="$router.push({path:'refundDetail',query:{ id:detail.listOrderItem[0].id,refundId:detail.listOrderItem[0].status}})"
        >
        查看详情
        </van-button> -->

      <!-- 待评价 -->
      <div v-if="status == 3 && !appraisesStatus - 0">
        <!-- <van-button
          class="bottom-btn color999"
          size="small"
          style="border-color: #999"
          >评价</van-button
        > -->
      </div>
      
    </div>
    <!-- 底部 -->

    <order-pay
      :pay-show="payShow"
      :price=" detail.paymentPrice"
      :order-no="$route.query.id"
      :id="id"
      @close="payShow = false"
    ></order-pay>
  </div>
</template>
<script>
import {receive} from '../../api/ordersCenter/index'
import { Dialog } from 'vant'
import Head from '../../components/head/index.vue'
import { getOrderDetail, cancelOrder, receiveOrder } from "@/api/orderDetail";
import copyText from "@/components/copy-text";
import orderPay from "@/components/mallPay";
export default {
  name: "orders",
  components: { copyText, orderPay,Head },
  data() {
    return {
      sellAll:0,
      mall:null,
      id: null,
      orderNo: null,
      status: null,
      isPay: null,
      appraisesStatus: null,
      detail: {
        paymentPrice: null,
        remark: null,
        orderNo: null,
        transactionId: null,
      },
      shopName: null,
      locate: {
        address: null,
        userName: null,
        telNum: null,
      },
      spu: {
        spuName: null,
        salesPrice: null,
        picUrl: null,
        specInfo: null,
        quantity: null,
      },
      list: [],
      list2: [],

      payShow: false,
      orderInfo:''
    };
  },
  created() {
    this.id = this.$route.query.id;
  this.getDetail()
    
  },
  mounted(){ 
    this.setRem()
    window.closePage=this.closePage
  },
  activated() {
    this.$toast("activated");
    this.getDetail();
  },
 
  
  methods: {
    getDetail() {
      getOrderDetail(this.id).then((res) => {

         
        this.mall=res.data.data
        console.log(res);
        this.orderInfo=res.data.data
        this.detail = res.data.data;
         this.detail.listOrderItem.forEach( item => { 

            this.sellAll += item.salesPrice * item.quantity
            })
        this.orderNo = this.detail.orderNo;
        this.status = this.detail.status;
        this.isPay = this.detail.isPay;
        this.appraisesStatus = this.detail.appraisesStatus;
        this.shopName = this.detail.shopInfo.name;
        this.locate = this.detail.orderLogistics;
        this.spu = res.data.data.listOrderItem[0];
        this.list = [
          { title: "商品总价", value: "￥" +  this.sellAll.toFixed(2)  },
          { title: "运费(快递)", value: "￥" + (this.detail.freightPrice).toFixed(2) },
          { title: "优惠券", value: "-￥" +  (this.detail.listOrderItem[0].paymentCouponPrice) },
        ];
        this.list2 = [
          { title: "创建时间：", value: this.detail.createTime },
          { title: "付款时间：", value: this.detail.paymentTime },
          { title: "发货时间：", value: this.detail.deliveryTime },
          { title: "成交时间：", value: this.detail.closingTime },
        ];
      });
    },
    // 取消订单
    cancelOrder() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定取消该订单吗？",
        })
        .then(() => {
          // on confirm
          cancelOrder(this.id).then((res) => {
           if(res.data.code===0){ 
             this.$toast.success('取消成功')
            this.getDetail();
            // sessionStorage.setItem('status',JSON.stringify({status:'5',attribute:'status',index:this.$route.query.number}))

           }else{ 
             this.$toast.fail('取消失败')
           }
            
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 确认收货
    receiveOrder() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认收货？",
        })
        .then(() => {
          // on confirm
          receiveOrder(this.id).then((res) => {
            console.log(res);
            this.getDetail();
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    reloadPage() {
      this.getDetail();
    },
    goGoodsDetail(){
      this.$router.push({
        path:'detailsgoods',
        query:{
          id:this.orderInfo.listOrderItem[0].spuId
        }
      })
    },
    closePage(){ 
      location.reload()
    },
    Receiving(){ 

          Dialog.alert({
            title: '确认收货',
            message: '是否确认收货？',
          }).then(() => {
            

            receive(this.mall.id).then(res=>{ 
                        if(res.data.code===0){ 
                          this.$toast.success('收货成功')
                          this.getDetail()
                          //
                        }else{ 
                          this.$toast.fail('收货失败')
                        }
                      })

          })


            
          },

          goRefundDetail(){ 

            

           

                 this.$router.push({ 
                path:'refundDetail',
                query:{ 
                  id:this.detail.listOrderItem[0].id,
                  refundId: this.detail.orderRefundStatu == 12? 2:this.detail.orderRefundStatu == 11?3:1,
                  type:'1'
                  
                }
                
              })
            
          }
  },
 
};
</script>
<style lang="less" scoped>
.page{ 
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column ;
  box-sizing: border-box;
}
.page::-webkit-scrollbar{ 
  display: none;
}
.main{ 
  background-color: #f6f6f6;
  padding: .266667rem;
  overflow: auto;
  flex: 1;
}
.orders {
  p {
    margin-bottom: 2px;
  }
}
.list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  white-space: nowrap;
  
}
.bottom {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 14px 16px;
  background-color: #fff;
  border-top: 1px solid #eee;
  .bottom-btn {
    width: 84px;
    height: 28px;
    border-radius: 14px;
    margin-left: 10px;
  }
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

}
.van-button{ 
  margin-bottom: .266667rem;
}
 /deep/ img{ 
  border-radius: .266667rem !important;
}
.color333{ 
  display: flex;
  
}
.name{ 
  width: 150px;
  word-break:normal;
}
</style>
