<template>
  <van-popup
    v-model="show"
    closeable
    close-icon-position="top-left"
    position="bottom"
    @close="$emit('close', false)"
    :style="{ height: '50%' }"
  >
    <div class="popup-inner">
      <div class="price color333">
        <span class="fz18">￥</span>
        <span class="price-num">{{ price }}</span>
      </div>

      <van-radio-group v-model="payType">
       

        <van-cell>
          <template #title>
            <van-image
              width="24"
              height="24"
              src="/img/pay/weChat-icon.png"
              style="vertical-align: middle; margin-right: 6px"
            />
            <span style="line-height: 24px">微信</span>
          </template>
          <template #right-icon>
            <van-radio name="1" />
          </template>
        </van-cell>
         <van-cell>
          <template #title>
            <van-image
              width="24"
              height="24"
              src="/img/pay/alipay-icon.png"
              style="vertical-align: middle; margin-right: 6px"
            />
            <span style="line-height: 24px">支付宝</span>
          </template>
          <template #right-icon>
            <van-radio name="2" />
          </template>
        </van-cell>
      </van-radio-group>

      <van-button type="info" class="bottom-btn" @click="pay"
        >立即付款</van-button
      >
    </div>
  </van-popup>
</template>

<script>
// import { submitAlipay, submitWxpay } from "@/api/sureOrder/sureOrder";
import { mallPay} from '../../common/mallPay'
export default {
  name: "orderPay",
  props: {
    payShow: {
      type: Boolean,
      required: true,
    },
    price: {
      required: false,
    },
    orderNo: {
      required: false,
    },
    id: {
      required: false,
    },
  },
  data() {
    return {
      show: false,
      payType: "1",
    };
  },
 
  methods: {



    pay() {

      mallPay(this.orderNo,this.payType)
        this.$emit("close", false);



      // if (this.payType == "1") {
      //   // 支付宝支付
      //   let param = {
      //     outTradeNo: this.orderNo,
      //     pamentPrice: this.price,
      //   };
      //   submitAlipay(param).then((res) => {
        
      //     this.alipay(res.data);
      //     this.show = false;
        
      //     this.payType = "1";
      //   });
      // } else if (this.payType == "2") {
      //   // 微信支付
      //   let param = {
      //     id: this.id,
      //     tradeType: "APP",
      //   };
      //   submitWxpay(param).then((res) => {
        
      //     this.wxpay(res.data.data);
      //     this.show = false;
      //     this.$emit("close", false);
      //     this.payType = "1";
      //   });
      // }
    },
    // 调原生 支付宝支付
    alipay(sign) {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.alipay.postMessage(sign);
      }
      if (isAndroid) {
        window.android.alipay(sign);
      }
    },
    // 调原生 微信支付
    wxpay(data) {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        // window.webkit.messageHandlers.alipay.postMessage("");
        window.webkit.messageHandlers.wechatPay.postMessage(
          JSON.stringify(data)
        );
      }
      if (isAndroid) {
        window.android.wxpay(
          data.appId,
          data.partnerId,
          data.prepayId,
          data.nonceStr,
          data.timeStamp,
          data.packageValue,
          data.sign
        );
      }
    },
  },
  watch: {
    payShow() {
      this.show = this.payShow;
    },
  },

 
};
</script>

<style lang="less" scoped>
.van-popup {
  .popup-inner {
    padding-top: 40px;
    .price {
      text-align: center;
      font-weight: 500;
      .price-num {
        font-size: 28px;
      }
    }
    .bottom-btn {
      position: absolute;
      bottom: 40px;
      width: 90%;
      margin-left: 5%;
    }
  }
}
</style>